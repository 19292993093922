<template>
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="录入商品"
    ></el-page-header>
    <div id="select-container">
      <div class="top-autocomplate">
        <span class="text">请输入会员账号称进行入库操作：</span>
        <el-select
          v-model="vipNo"
          filterable
          remote
          reserve-keyword
          placeholder="请输入会员账号"
          :remote-method="remoteMethodVIP"
          :loading="loading"
          style="width: 25%; margin-right: 2%"
        >
          <el-option
            v-for="item in vipOptions"
            :key="item.vipValue"
            :label="`${item.name}`"
            :value="item.cardNo"
          >
          </el-option>
        </el-select>
      </div>
      <el-form
        style="margin-top: 2%"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        v-if="showStyle"
        label-position="left"
      >
        <el-form-item label="商品名称" prop="name">
          <el-input style="width: 50%" v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="商品数量" prop="num">
          <el-input-number
            v-model="productNum"
            controls-position="right"
            :min="1"
            :controls="false"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="商品单位" prop="unit">
          <el-input
            style="width: 30%"
            v-model="unit"
            placeholder="个、箱、包..."
            :controls="false"
          ></el-input>
        </el-form-item>
        <el-form-item label="入库价" prop="price">
          <el-input-number
            v-model="inPrice"
            :precision="2"
            :step="1"
            :controls="false"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="入库时间" required>
          <el-col :span="11">
            <el-form-item prop="date">
              <el-input
                style="width: 70%"
                disabled
                v-model="ruleForm.date"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="合同编号" required>
          <el-col :span="11">
            <el-form-item prop="date">
              <el-input
                style="width: 70%"
                disabled
                v-model="productNo"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="保质期(天)">
          <el-input-number
            v-model="expiration"
            :step="1"
            :controls="false"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="出库价">
          <el-input-number
            v-model="outPrice"
            :precision="2"
            :step="1"
            :controls="false"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="商品分类" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择商品类别">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品状态" prop="type">
          <el-select v-model="ruleForm.storeState" placeholder="请选择商品状态">
            <el-option
              v-for="item in storeState"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品图片">
          <input type="file" :disabled="disabledImage" @change="onchangemd" />
          <div class="files_list">
            <div
              class="files_list_childer"
              v-for="(item, index) in filesImage"
              :key="index"
            >
              {{ item.imageName }}
              <span @click="files_D(item)" class="files_D el-icon-close"></span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="ruleForm.remark"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            :disabled="disabledShow"
            type="primary"
            @click="submitForm('ruleForm')"
            >保存入库</el-button
          >
          <el-button @click="resetForm('ruleForm')">取消</el-button>
        </el-form-item>
        <el-form-item label="预览商品图片">
          <div class="swiper-item__content">
            <div
              class="swiper_img_list"
              v-for="(item, index) in preview"
              :key="index"
            >
              <img :src="item" alt="" />
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
<script>
import moment from "moment";
import axios from "axios";
import secret from "@/utils/secret.js";
export default {
  data() {
    return {
      radio: "1",
      disabledShow: true,
      vipNo: "",
      vipName: "",
      belongTo: "",
      productNo: "",
      // barcodeValue: "", // 条形码
      showStyle: true, // 控制显示隐藏
      productNum: 1, //商品数量
      unit: '', // 商品单位
      inPrice: 0, // 入库价
      outPrice: 0, // 出库价
      classify: "", // 分类
      picture: "", // 图片
      disabledImage: false,
      expiration: 99, // 保质期
      vipValue: "",
      loading: false,
      vipOptions: [],
      preview: [], // 图片预览
      userKey: "",
      ruleForm: {
        name: "",
        date: "",
        type: [],
        storeState: 'u',
        remark: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
          {
            min: 1,
            message: "长度在 2 到 24 个字符",
            trigger: "blur",
          },
        ],
      },
      filesImage: [],
      storeState: [
        {
          value: 'u',
          label: '正常'
        },
        {
          value: 'd',
          label: '下架'
        }
      ],
      options: [
        {
          value: "BH",
          label: "百货",
        },
        {
          value: "TH",
          label: "特惠",
        },
        {
          value: "KQ",
          label: "卡券",
        },
        {
          value: "MZ",
          label: "美妆",
        },
        {
          value: "JZ",
          label: "家电",
        },
        {
          value: "MS",
          label: "美食",
        },
        {
          value: "FS",
          label: "服饰",
        },
        {
          value: "QT",
          label: "其它",
        },
      ],
    };
  },
  created() {
    this.userKey = secret.Decrypt(localStorage.getItem("user_key"));
    this.ruleForm.date = moment().format("YYYY-MM-DD HH:mm:ss");
    this.state = "";
  },
  watch: {
    vipNo: function () {
      this.vipNo === ""
        ? console.log("VIP_WATCH")
        : (this.disabledShow = false);
    },
    // vipOptions: {
    //   handler: function () {
    //     this.productNo = +this.vipOptions[0].contractNo;
    //   },
    //   deep: true,
    // },
  },
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    // 处理图片
    onchangemd(e) {
      this.disabledImage = true;
      const files = e.target.files[0];
      const isLt5M = +files.size > 5242880 ? true : false;
      if (isLt5M) {
        this.$message({
          type: "worning",
          message: "图片大小不允许大于5M！",
        });
        this.disabledImage = false;
      } else if (
        files.type === "image/png" ||
        files.type === "image/jpeg" ||
        files.type === "image/jpg"
      ) {
        let formdata = new FormData();
        formdata.append("uploadFile", files);
        axios({
          method: "post",
          url: this.GLOBAL.file,
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          console.log(res);
          this.filesImage.push({
            id: this.filesImage.length,
            imageName: files.name,
            imageUrl: res.data,
          });
          this.preview.push(this.GLOBAL.file + res.data.split("upload")[1]);
        });
        this.$message({
          type: "success",
          message: "上传成功",
        });
        this.disabledImage = false;
      } else {
        this.$message({
          type: "worning",
          message: "图片格式不正确",
        });
        this.disabledImage = false;
      }
    },
    files_D(e) {
      this.filesImage = this.filesImage.filter((item) => {
        return item.id !== e.id;
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    // search vipNo.
    remoteMethodVIP(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          const cardNo = query;
          const dimSearch = true;
          let myStoreKey = this.userKey;
          this.vipOptions = axios
            .post(this.GLOBAL.api + "/creat/vip", {
              dimSearch,
              cardNo,
              myStoreKey,
            })
            .then((item) => {
              this.vipName = item.data[0] ? item.data[0].name : '';
              this.productNo = item.data[0] ? item.data[0].contractNo : '';
              return (this.vipOptions = item.data);
            });
          this.loading = false;
        }, 200);
      } else {
        this.vipOptions = [];
      }
    },
    // 点击显示
    search() {
      if (this.state !== "") {
        this.showStyle = true;
      }
    },
    submitForm(formName) {
      let radio = this.radio;
      let belongTo = this.vipName;
      let ruleForm = this.ruleForm;
      let productNo = this.productNo;
      let unit = this.unit;
      let picture = this.picture;
      let inPrice = this.inPrice;
      let outPrice = this.outPrice;
      let classify = this.ruleForm.type ? this.ruleForm.type : "OT"; // delete
      let productNum = this.productNum;
      let expiration = this.expiration;
      let imageInfo = JSON.stringify(this.filesImage);
      let userKey = this.userKey;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .post(this.GLOBAL.api + "/entering", {
              radio,
              belongTo,
              productNo,
              ruleForm,
              unit,
              picture,
              inPrice,
              outPrice,
              classify,
              productNum,
              expiration,
              imageInfo,
              userKey,
            })
            .then((data) => {
              if (data.data.statusCode == 400) {
                this.$message({
                  message: "录入失败，请核对信息",
                  type: "warning",
                });
              } else {
                this.$message({
                  message: "商品录入成功",
                  type: "success",
                });
                this.$router.push("/product/list");
              }
            });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  mounted() {},
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 0 1%;
}
.box-card {
  width: 100%;
  height: 100%;
}
.top-autocomplate {
  margin: 10px 0 0 3%;
}
.el-col-11 {
  width: 28.83333%;
}
.demo-ruleForm {
  margin-left: 2%;
}
.swiper-item__content {
  display: flex;
}
.swiper_img_list {
  width: 20%;
}
.swiper_img_list img {
  width: 100%;
}
@media (min-width: 768px) {
  .box-card {
    width: 100%;
  }
}
</style>